import { Box, Button, Container, Typography } from '@mui/material';
import OctPhishingContestTranslations from 'csam/translations/OctPhishingContestTranslations';
import { downloadPDF } from 'csam/utils/Constants';
import React from 'react';

interface PhishingGuidelineProps {
  documentData: any;
}

const PhishingGuideline: React.FC<PhishingGuidelineProps> = ({ documentData }) => {
  const localeFromStorage = localStorage.getItem('locale') ?? 'en';
  const getTranslation = (key: keyof (typeof OctPhishingContestTranslations)['en']) =>
    OctPhishingContestTranslations[localeFromStorage as keyof typeof OctPhishingContestTranslations]?.[key] ||
    OctPhishingContestTranslations.en[key];

  const handleDownload = async () => {
    const fileName = documentData.contestDocument.image;
    const token = sessionStorage.getItem('Auth_token'); // Replace with your actual token key

    if (!token) {
      console.error('No token found in session storage');
      return;
    }

    try {
      const response = await fetch(`${downloadPDF}download-document?fileName=${encodeURIComponent(fileName)}`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const blob = await response.blob();
      const downloadUrl = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = downloadUrl;
      link.download = fileName.split('/').pop() || 'document.pdf';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error('Error downloading document:', error);
    }
  };

  return (
    <Box className="guidelines mb-5">
      <Container>
        <Box className="guidelines-main">
          <Box className="number-wrapper-1">
            <Typography component="span" className="number-wrapper">
              <Typography component="span" className="white">
                {getTranslation('guidelines')}
              </Typography>
            </Typography>
          </Box>
          <ul>
            <li>{getTranslation('listItem1')}</li>
            <li>{getTranslation('listItem2')}</li>
            <li>{getTranslation('listItem3')}</li>
            <li>{getTranslation('listItem4')}</li>
            <li>
              {getTranslation('listItem5')}
              <span className="text-abi-yellow"> {getTranslation('listItem6')}</span>
            </li>
          </ul>
          <Box className="text-center pb-5">
            <Button
              variant="contained"
              color="primary"
              sx={{ fontWeight: 600, color: '#000' }}
              onClick={handleDownload}
            >
              {getTranslation('guidelineButtonText')}
            </Button>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default PhishingGuideline;

const GamesSingleTranslations = {
  en: {
    bannerHeading: 'GAMES',
    bannerSubHeading: 'SECURITY & COMPLIANCE AWARENESS MONTH',
    sectionHeading: 'Play and win points',
  },
  es: {
    bannerHeading: 'Juegos',
    bannerSubHeading: 'Mes de Concienciación en Seguridad y Cumplimiento',
    sectionHeading: 'Juega y gana puntos',
  },
  pt: {
    bannerHeading: 'Jogos',
    bannerSubHeading: 'Mês de Conscientização em Segurança e Conformidade',
    sectionHeading: 'ogue e ganhe pontos',
  },
  zh: {
    bannerHeading: '游戏 ',
    bannerSubHeading: '安全与合规意识月 (Ānquán yǔ Hégūi Yìshi Yuè)',
    sectionHeading: '玩游戏赢取积分',
  },
};

export default GamesSingleTranslations;

import { Box, Container, Grid, Typography } from '@mui/material';
import OctPhishingContestTranslations from 'csam/translations/OctPhishingContestTranslations';
import React from 'react';

const OctPhishingKeyFetures: React.FC = () => {

  const localeFromStorage = localStorage.getItem('locale') ?? 'en';
  const getTranslation = (key: keyof (typeof OctPhishingContestTranslations)['en']) =>
    OctPhishingContestTranslations[localeFromStorage as keyof typeof OctPhishingContestTranslations]?.[key] || OctPhishingContestTranslations.en[key];

  return (
    <Box className="keyFeturesPhishing">
      <Container sx={{ pb: 4, pt: 4, textAlign: 'center' }}>
        <Typography variant="h6">{getTranslation('heading1')}</Typography>
        <Typography variant="h6">
          {getTranslation('heading2')}
        </Typography>
      </Container>

      <Container sx={{ pb: 4, pt: 4, textAlign: 'center' }}>
        <Grid container spacing={4}>
          <Grid item md={4} xs={12}>
            <Box className="pledgeCard">
              <img src="/images_public/email-icon-1.png" alt="Pledge Icon" />
              <Typography variant="body1" mt={4}>
                {getTranslation('cardText1')}
              </Typography>
            </Box>
          </Grid>

          <Grid item md={4} xs={12}>
            <Box className="pledgeCard">
              <img src="/images_public/email-icon-2.png" alt="Pledge Icon" />
              <Typography variant="body1" mt={4}>
                {getTranslation('cardText2')}
              </Typography>
            </Box>
          </Grid>

          <Grid item md={4} xs={12}>
            <Box className="pledgeCard">
              <img src="/images_public/email-icon-3.png" alt="Pledge Icon" />
              <Typography variant="body1" mt={4}>
                {getTranslation('cardText3')}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Container>

      <Container sx={{ pb: 4, pt: 4, textAlign: 'center' }}>
        <Box className="do-your-part">
          <Typography component="span">{getTranslation('contestTags')}</Typography>
        </Box>
      </Container>
    </Box>
  );
};

export default OctPhishingKeyFetures;

import {
  Box,
  Button,
  CircularProgress,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import AdminPageLoader from 'csam/admin/components/AdminPageLoader';
import AdminWrapper from 'csam/admin/components/AdminWrapper';
import LocaleButtonGroup from 'csam/admin/components/LocaleButtonGroup';
import useTranslatableFields from 'csam/admin/hooks/UseTranslateble,';
import AdminInternalError from 'csam/admin/pages/AdminInternalError';
import { useAuthenticatedMutation, useAuthenticatedQuery } from 'csam/api/api';
import LocaleContext from 'csam/components/LocaleContext';
import { fixed } from 'csam/utils/Constants';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

interface DaysData {
  october_week_id: number;
  day: number;
  day_name: string;
  status: boolean;
  video: string;
  tip_title: string;
  tip_description: string;
  [key: string]: string | number | boolean;
}

interface TranslationData {
  day_name: string;
  video: string;
  tip_title: string;
  tip_description: string;
  [key: string]: unknown;
}

interface State {
  data: DaysData;
  translations: {
    es: TranslationData;
    pt: TranslationData;
    zh: TranslationData;
  };
}

const AddOctDays = () => {
  const initialState = {
    data: {
      october_week_id: 0,
      day: 0,
      day_name: '',
      status: false,
      video: '',
      tip_title: '',
      tip_description: '',
    },
    translations: {
      es: { day_name: '', video: '', tip_title: '', tip_description: '' },
      pt: { day_name: '', video: '', tip_title: '', tip_description: '' },
      zh: { day_name: '', video: '', tip_title: '', tip_description: '' },
    },
  };

  const [daysData, setDaysData] = useState<State>(initialState);
  const { locale } = useContext(LocaleContext);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const { id } = useParams<{ id?: string }>();

  const slug = `october_days`;

  const { translatePending, errorTranslate, translateData, disabledFields, setLocale } = useTranslatableFields(
    slug,
    initialState,
  );

  useEffect(() => {
    if (!id) {
      setLocale('en');
    }
  }, [id, setLocale]);

  const query = useAuthenticatedQuery(
    ['october_days', id],
    {
      url: `${fixed}admin/en/october_days/${id}`,
    },
    {
      enabled: !!id,
    },
  );

  useEffect(() => {
    if (query.data && query.data.success) {
      setDaysData(query.data.data as State);
    }
  }, [query.data]);

  const isUpdateForm = Boolean(id);

  const mutation = useAuthenticatedMutation(
    isUpdateForm ? ['update-day', 'PUT'] : ['add-day', 'POST'],

    (data) => {
      const headers = data instanceof FormData ? {} : { 'Content-Type': 'application/json' };

      return {
        url: `${fixed}admin/${locale}/october_days${isUpdateForm ? `/${id}` : ''}`,

        method: isUpdateForm ? 'PUT' : 'POST',

        headers,

        data,
      };
    },
  );

  const {
    data: weeksData,
    error: weeksError,
    isPending: weeksIsPending,
  } = useAuthenticatedQuery(['fetch-weeks', 'GET'], {
    url: `${fixed}admin/en/october_weeks`,
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  });

  interface WeekData {
    id: number;
    week: string;
  }

  if (translatePending) return <AdminPageLoader />;
  if (errorTranslate) return <Box>Error: {errorTranslate.message}</Box>;
  if (translateData && !translateData.success) return <AdminInternalError />;

  if (weeksIsPending) return <AdminPageLoader />;
  if (weeksError) return <Box>Error: {weeksError.message}</Box>;
  if (weeksData && !weeksData.success) return <AdminInternalError />;

  const handleChange = (event: { target: { name: string; value: string | number | boolean; checked?: boolean } }) => {
    let { name, value, checked } = event.target;
  
    if (name === 'status') {
      value = checked ? true : false;
    }
  
    setDaysData((prevState) => {
      let newData = prevState.data;
      let newTranslations = prevState.translations;
  
      if (name === 'day') {
        const workingDays = getWorkingDaysInOctober();
        const selectedDay = workingDays.find((d) => d.day === Number(value));
        if (selectedDay) {
          newData = {
            ...newData,
            day: Number(value),
            day_name: selectedDay.dayName,
          };
        }
      } else if (locale !== 'en' && translateData?.data.includes(name)) {
        newTranslations = {
          ...prevState.translations,
          [locale as keyof typeof prevState.translations]: {
            ...(prevState.translations[locale as keyof typeof prevState.translations] || {}),
            [name]: value,
          },
        };
      } else {
        newData = {
          ...newData,
          [name]: value,
        };
      }
  
      return {
        data: newData,
        translations: newTranslations,
      };
    });
  };

  const appendToFormData = (formData: FormData, data: Record<string, unknown>, baseKey: string) => {
    Object.entries(data).forEach(([key, value]) => {
      const fullKey = `${baseKey}[${key}]`;

      if (value instanceof File) {
        formData.append(fullKey, value);
      } else if (typeof value === 'object' && value !== null) {
        formData.append(fullKey, JSON.stringify(value));
      } else {
        formData.append(fullKey, String(value));
      }
    });
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setLoading(true);

    const formData = new FormData();

    const data = { ...daysData };

    if (data) {
      delete data.translations;
    }

    appendToFormData(formData, data, 'data');

    if (daysData?.translations) {
      Object.entries(daysData.translations).forEach(([localeKey, translation]) => {
        appendToFormData(formData, translation, `translations[${localeKey}]`);
      });
    }

    try {
      await mutation.mutateAsync(formData, {
        onSuccess: () => {
          toast.success(`Record ${isUpdateForm ? 'Updated' : 'Added'} Successfully`);
          setTimeout(() => {
            navigate('/admin/october-events');
          }, 1000);
        },

        onError: () => {
          console.log('An error occurred while saving the data.');
        },
      });
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };
  const getWorkingDaysInOctober = () => {
    const year = new Date().getFullYear();
    const workingDays: { day: number; dayName: string }[] = [];
    for (let day = 1; day <= 31; day++) {
      const date = new Date(year, 9, day); // October is month 9 (0-indexed)
      const dayOfWeek = date.getDay();
      if (dayOfWeek !== 0 && dayOfWeek !== 6) { // Exclude Sundays (0) and Saturdays (6)
        workingDays.push({ day, dayName: date.toLocaleDateString('en-US', { weekday: 'long' }) });
      }
    }
    return workingDays;
  };
  const workingDays = getWorkingDaysInOctober();

  const handleSetLocale = (newLocale: string) => {
    if (locale === 'en' && newLocale !== 'en') {
      for (const key in daysData.data) {
        if (
          daysData.data[key] === undefined ||
          daysData.data[key] === '' ||
          (daysData.data[key] === 0 && key !== 'status')
        ) {
          toast.error(`Please fill in the ${key} field first`);
          return;
        }
      }
    }

    setLocale(newLocale);
  };

  return (
    <AdminWrapper>
      <Box className="pageHeader">
        <Box sx={{ display: 'flex', gap: 2 }}>
          <Typography variant="h6"> {isUpdateForm ? 'Edit October Day' : 'Add October Day'} </Typography>
        </Box>
        <LocaleButtonGroup currentLocale={locale} setLocale={handleSetLocale} />
      </Box>
      <Box component="form" onSubmit={handleSubmit} encType="multipart/form-data">
        <Grid container spacing={2}>
          <Grid item sm={6}>
            <InputLabel id="week-label">Week</InputLabel>
            <Select
              fullWidth
              labelId="week-label"
              name="october_week_id"
              size="small"
              value={daysData.data.october_week_id === 0 ? '' : daysData.data.october_week_id.toString()}
              onChange={handleChange}
              disabled={disabledFields.includes('october_week_id')}
              required={locale === 'en'}
            >
              {(weeksData.data as WeekData[]).map((week: WeekData) => (
                <MenuItem key={week.id} value={week.id.toString()}>
                  {week.week}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item sm={6}>
            <Box className="form-group">
              <InputLabel id="day-label">
                Day Number <Typography component="sup">{locale.toUpperCase()}</Typography>
              </InputLabel>
              <Select
                labelId="day-label"
                name="day"
                size="small"
                value={daysData.data.day}
                onChange={handleChange}
                disabled={disabledFields.includes('day')}
                required={locale === 'en'}
                fullWidth
              >
                {workingDays.map((day) => (
                  <MenuItem key={day.day} value={day.day}>
                    {day.day}
                  </MenuItem>
                ))}
              </Select>
            </Box>
          </Grid>

          <Grid item sm={6}>
            <Box className="form-group">
              <InputLabel id="dayName-label">
                Day Name <Typography component="sup">{locale.toUpperCase()}</Typography>
              </InputLabel>
              <TextField
                placeholder="Add Day Name"
                size="small"
                name="day_name"
                value={
                  locale === 'en'
                    ? daysData.data.day_name
                    : daysData.translations[locale as keyof State['translations']]?.day_name || ''
                }
                onChange={handleChange}
                disabled={disabledFields.includes('day_name')}
                required={locale === 'en'}
                fullWidth
              />
            </Box>
          </Grid>

          <Grid item sm={6}>
            <Box className="form-group">
              <InputLabel id="video-label">
                Video <Typography component="sup">{locale.toUpperCase()}</Typography>
              </InputLabel>
              <TextField
                placeholder="Add Video URL"
                size="small"
                name="video"
                value={
                  locale === 'en'
                    ? daysData.data.video
                    : daysData.translations[locale as keyof State['translations']]?.video || ''
                }
                onChange={handleChange}
                disabled={disabledFields.includes('video')}
                required={locale === 'en'}
                fullWidth
              />
            </Box>
          </Grid>

          <Grid item sm={12}>
            <Box className="form-group">
              <InputLabel id="tipTitle-label">
                Tip Title <Typography component="sup">{locale.toUpperCase()}</Typography>
              </InputLabel>
              <TextField
                placeholder="Add Tip Title"
                size="small"
                name="tip_title"
                value={
                  locale === 'en'
                    ? daysData.data.tip_title
                    : daysData.translations[locale as keyof State['translations']]?.tip_title || ''
                }
                onChange={handleChange}
                disabled={disabledFields.includes('tip_title')}
                required={locale === 'en'}
                fullWidth
              />
            </Box>
          </Grid>

          <Grid item sm={12}>
            <Box className="form-group">
              <InputLabel id="tipDescription-label">
                Tip Description <Typography component="sup">{locale.toUpperCase()}</Typography>
              </InputLabel>
              <TextField
                placeholder="Add Tip Description"
                size="small"
                name="tip_description"
                value={
                  locale === 'en'
                    ? daysData.data.tip_description
                    : daysData.translations[locale as keyof State['translations']]?.tip_description || ''
                }
                onChange={handleChange}
                disabled={disabledFields.includes('tip_description')}
                required={locale === 'en'}
                fullWidth
                multiline
                rows={6}
              />
            </Box>
          </Grid>

          <Grid item sm={6}>
            <Box className="form-group">
              <InputLabel id="status-label">Status</InputLabel>
              <Grid container alignItems="center">
                <Typography>Inactive</Typography>
                <Switch
                  id="status-label"
                  name="status"
                  onChange={handleChange}
                  checked={Boolean(daysData.data.status)}
                  disabled={locale !== 'en'}
                />
                <Typography>Active</Typography>
              </Grid>
            </Box>
          </Grid>

          <Grid item sm={12}>
            <Button type="submit" variant="contained" color="primary" disabled={loading}>
              {loading && <CircularProgress size={16} sx={{ mr: 1 }} />} Submit
            </Button>
          </Grid>
        </Grid>
      </Box>
    </AdminWrapper>
  );
};

export default AddOctDays;